import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Checkbox,
  Card,
  CardContent,
  CardActions,
  Fab,
  FormControlLabel,
  FormGroup,
  Grow,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    background: 'rgba(0,0,0,0.67)',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [unit, setUnit] = useState(null);
  const [status, setStatus] = useState('close');

  useEffect(() => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { unit } = doc.data();
          setUnit(unit);
        }
      });

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { status } = doc.data();
          setStatus(status);
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.main}>
      {status === 'close' && <div className={classes.overlay} />}
      {unit && unit.unit_type === 'poll' && <Poll unit={unit} />}
      {unit && unit.unit_type === 'qa' && <Question unit={unit} />}
    </div>
  );
};

const Poll = ({ unit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [choose, setChoose] = useState([]);
  const [open, setOpen] = useState(false);

  const renderSingleType = () =>
    unit.content.type === 'single' && (
      <RadioGroup
        value={`${choose[0]}`}
        onChange={(event) => setChoose([parseInt(event.target.value, 10)])}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        {unit.content.options.map((option) => (
          <FormControlLabel
            key={`option-${option.value}`}
            control={<Radio color="secondary" />}
            label={`${option.item}`}
            value={`${option.value}`}
          />
        ))}
      </RadioGroup>
    );

  const renderMultiType = () =>
    unit.content.type !== 'single' && (
      <FormGroup style={{ flexDirection: 'row' }}>
        {unit.content.options.map((option, index) => (
          <FormControlLabel
            key={`option-${option.value}`}
            control={
              <Checkbox
                color="secondary"
                value={`${option.value}`}
                onChange={(event) => {
                  choose[index] = event.target.checked;
                }}
              />
            }
            label={`${option.item}`}
            value={`${option.value}`}
          />
        ))}
      </FormGroup>
    );

  const send = () => {
    let result = 0;
    if (unit.content.type === 'single') {
      [result] = choose;
    } else {
      choose.forEach((i, j) => {
        if (i) {
          result += Math.pow(2, j);
        }
      });
    }

    if (result) {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/oneknow`)
        .update({
          [`student_${state.myInfo.ID}`]: {
            name: state.myInfo.Name,
            content: result,
          },
        })
        .then(() => setOpen(true));
    }
  };

  return (
    <Grow in timeout={1000}>
      <div className={classes.content}>
        <Card style={{ overflow: 'auto', maxWidth: 600 }}>
          <CardContent
            dangerouslySetInnerHTML={{
              __html: unit.content.content,
            }}
          />
          <CardActions
            style={{
              border: '1px solid #ccc',
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1),
              margin: theme.spacing(2),
            }}
          >
            {renderSingleType()}
            {renderMultiType()}
          </CardActions>
          <CardActions style={{ padding: 16 }}>
            <div style={{ flex: 1 }} />
            <Tooltip title="提交答案" placement="top">
              <Fab color="secondary" onClick={send}>
                <SendIcon />
              </Fab>
            </Tooltip>
          </CardActions>
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          message="作答結果已送出"
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={3000}
        />
      </div>
    </Grow>
  );
};

const Question = ({ unit }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [result, setResult] = useState('');
  const [open, setOpen] = useState(false);

  const send = () => {
    if (result) {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/oneknow`)
        .update({
          [`student_${state.myInfo.ID}`]: {
            name: state.myInfo.Name,
            content: result,
          },
        })
        .then(() => setOpen(true));
    }
  };

  return (
    <Grow in timeout={1000}>
      <div className={classes.content}>
        <Card style={{ overflow: 'auto', maxWidth: 600 }}>
          <CardContent dangerouslySetInnerHTML={{ __html: unit.content }} />
          <CardActions style={{ padding: 16 }}>
            <TextField
              autoFocus
              fullWidth
              multiline
              label="請輸入你的答案..."
              variant="outlined"
              onChange={(e) => setResult(e.target.value)}
            />
          </CardActions>
          <CardActions style={{ padding: 16 }}>
            <div style={{ flex: 1 }} />
            <Tooltip title="提交答案" placement="top">
              <Fab color="secondary" onClick={send}>
                <SendIcon />
              </Fab>
            </Tooltip>
          </CardActions>
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          message="作答結果已送出"
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={3000}
        />
      </div>
    </Grow>
  );
};

export default Component;
