import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 2),
    minHeight: theme.spacing(6),
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  setting: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  noneItems: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 2, 0),
  },
  avatar: {
    borderWidth: 3,
    borderColor: 'transparent',
    borderStyle: 'solid',
  },
  balanceAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  plusAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  negAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

const SERVICE_MEMBERS =
  'https://m.1know.net/services/group/_GID_/members?accessToken=_ACCESS_TOKEN_';
const SERVICE_STUDENTS =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.student/classroom.GetStudents?rsptype=json&stt=session&sessionid=_SESSION_ID_&parser=spliter&content=UID:_UID_;Type:_TYPE_';

const Component = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const [mode, setMode] = useState('loading');
  const [scores, setScores] = useState({});
  const [students, setStudents] = useState([]);
  const [scoreAlert, setScoreAlert] = useState(true);

  useEffect(() => {
    if (!state.target) return;

    fetchStudents();

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const scores = doc.data();
          setScores(scores);
          setScoreAlert(Boolean(scores.target));
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.target]);

  const fetchStudents = () => {
    if (state.target.type === '1know') {
      fetch(
        SERVICE_MEMBERS.replace(/_GID_/gi, state.target.classroom.gid).replace(
          /_ACCESS_TOKEN_/gi,
          state.accessToken,
        ),
      )
        .then((response) => response.json())
        .then((response) => {
          const students = response
            .filter((item) => item.role === 'member')
            .map((item, i) => ({
              ID: item.uqid,
              ClassName: '',
              Name: `${item.last_name}${item.first_name}`,
              Photo: item.photo,
              SeatNo: i + 1,
            }));

          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        })
        .catch(() => {
          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        });
    } else if (state.target.type === 'custom') {
      const students = [];
      if (typeof state.target.classroom.students === 'number') {
        for (let i = 0; i < state.target.classroom.students; i += 1) {
          students.push({
            ID: i + 1,
            ClassName: '',
            Name: `學生 ${i + 1}`,
            Photo: Assets.Monster[i % 10],
            SeatNo: i + 1,
          });
        }
      } else {
        const names = state.target.classroom.students.split(',');
        names.forEach((name, i) => {
          if (name) {
            students.push({
              ID: i + 1,
              ClassName: '',
              Name: `${i + 1} ${name.trim()}`,
              Photo: Assets.Monster[i % 10],
              SeatNo: i + 1,
            });
          }
        });
      }

      window.localStorage.setItem('students', JSON.stringify(students));

      setStudents(students);
      dispatch({ type: 'SET_STUDENTS', payload: students });
      setMode('student');
    } else {
      fetch(
        SERVICE_STUDENTS.replace(/_DSNS_/gi, state.target.dsns)
          .replace(/_SESSION_ID_/gi, state.target.sessionid)
          .replace(/_UID_/gi, state.target.classroom.uid)
          .replace(/_TYPE_/gi, state.target.classroom.type),
      )
        .then((response) => response.json())
        .then((response) => {
          const students = []
            .concat(
              ((response.__Body || response).Students || {}).Student || [],
            )
            .map((student, idx) => ({
              ...student,
              Photo: student.Photo
                ? `data:image/png;base64,${student.Photo}`
                : Assets.Monster[idx % 10],
              DSNS: true,
            }));

          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        });
    }
  };

  const renderContent = () => {
    switch (mode) {
      case 'loading': {
        return (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        );
      }
      case 'student': {
        return (
          <List style={{ flex: 1 }}>
            {students.map((student, sdx) => (
              <React.Fragment key={`student-${student.ID}`}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={student.Photo}
                      className={classes.avatar}
                      imgProps={{ style: { backgroundColor: 'transparent' } }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      student.DSNS
                        ? `${student.SeatNo} ${student.Name}`
                        : student.Name
                    }
                    secondary={student.ClassName}
                  />
                  {scores[`student_${student.ID}`] !== undefined && (
                    <Avatar
                      className={
                        scores[`student_${student.ID}`] === 0
                          ? classes.balanceAvatar
                          : scores[`student_${student.ID}`] > 0
                          ? classes.plusAvatar
                          : classes.negAvatar
                      }
                    >
                      {scores[`student_${student.ID}`]}
                    </Avatar>
                  )}
                </ListItem>
                {sdx !== students.length - 1 && <Divider variant="inset" />}
              </React.Fragment>
            ))}
          </List>
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <div className={classes.root}>
      {scores.target && (
        <Dialog open={scoreAlert} onClose={() => setScoreAlert(false)}>
          <Box p={2} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h5">{scores.target.name}</Typography>
            <Box flex={1} />
            <IconButton onClick={() => setScoreAlert(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent
            style={{
              minWidth: 240,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h1"
              color={scores.target.score > 0 ? 'primary' : 'secondary'}
            >
              {scores.target.score > 0 ? '+1' : '-1'}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
      <div className={classes.header}>
        {mode === 'student' && (
          <Typography variant="h5">班級學生 {students.length} 人</Typography>
        )}
      </div>
      <div className={classes.content}>{renderContent()}</div>
    </div>
  );
};

export default Component;
