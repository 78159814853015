import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../App';

const Route = {
  Onecampus: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
  ],
  OnecampusClass: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
    { key: 'Class', label: '請選擇班級' },
  ],
  DsnsClass: [{ key: 'Class', label: '請選擇班級' }],
  OneknowOrgs: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
    { key: 'Org', label: '請選擇組織' },
  ],
  OneknowSelfGroup: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
    { key: 'Group', label: '請選擇群組' },
  ],
  OneknowOrgGroup: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
    { key: 'Org', label: '請選擇組織' },
    { key: 'Group', label: '請選擇群組' },
  ],
  ClassroomCode: [
    {
      key: 'School',
      label: '請選擇學校、1Know、教室代碼',
    },
    { key: 'Code', label: '請輸入教室代碼' },
  ],
};

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  buttonbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0, 0),
  },
  secondaryText: {
    color: theme.palette.secondary.main,
  },
}));

const SERVICE_USER_TOKEN =
  'https://us-central1-classroom-1campus.cloudfunctions.net/getUserToken?accessToken=_ACCESS_TOKEN_';
const SERVICE_ME_INFO =
  'https://auth.ischool.com.tw/services/me.php?access_token=_ACCESS_TOKEN_';
const SERVICE_USER_INFO =
  'https://m.1know.net/services/account/user?accessToken=_ACCESS_TOKEN_';
const SERVICE_CC_ITEMS =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.student/classroom.GetCCItems?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';
const SERVICE_GROUPS =
  'https://m.1know.net/services/group?accessToken=_ACCESS_TOKEN_';

const Component = ({ callback }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [tokenInfo] = useState(
    JSON.parse(window.localStorage.getItem('tokenInfo')),
  );
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [classesLoading, setClassesLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [roomCode, setRoomCode] = useState(tokenInfo.code || '');
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    fetch(SERVICE_USER_TOKEN.replace(/_ACCESS_TOKEN_/gi, tokenInfo.accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (!response.token) {
          window.localStorage.clear();
          window.location.reload();
          return;
        }

        firebase
          .auth()
          .signInWithCustomToken(response.token)
          .then(() => {
            if (tokenInfo.uid) {
              callback({
                action: 'SIGN_IN',
                payload: {
                  roomId: `${tokenInfo.dsns}.${tokenInfo.type.toLowerCase()}.${
                    tokenInfo.uid
                  }`,
                  accessToken: tokenInfo.accessToken,
                },
              });

              return;
            }

            if (tokenInfo.dsns !== '1campus') {
              setLoading(false);
              getSchool(tokenInfo);
              setSteps(Route.DsnsClass);
            } else {
              Promise.all([
                new Promise((rs) => {
                  fetch(
                    SERVICE_ME_INFO.replace(
                      /_ACCESS_TOKEN_/gi,
                      tokenInfo.accessToken,
                    ),
                  )
                    .then((response) => response.json())
                    .then((response) => {
                      rs(response);
                    });
                }),
                new Promise((rs) => {
                  fetch(
                    SERVICE_USER_INFO.replace(
                      /_ACCESS_TOKEN_/gi,
                      tokenInfo.accessToken,
                    ),
                  )
                    .then((response) => response.json())
                    .then((response) => {
                      rs(response);
                    })
                    .catch((error) => rs({ error }));
                }),
              ]).then(([me, user]) => {
                setLoading(false);
                setSchools(
                  [].concat(me.application || []).map((item) => ({
                    ...item,
                    avatar: item.name.substr(0, 1),
                    token: { ...tokenInfo, dsns: item.ap_name },
                    key: item.ap_name,
                  })),
                );

                if (!user.error) {
                  setOrgs([
                    {
                      key: '1know-self',
                      avatar: '個',
                      name: '1Know 個人',
                      dsns: '',
                    },
                    ...[].concat(user.orgs || []).map((item) => ({
                      key: `1know-${item.domain}`,
                      avatar: item.name.substr(0, 1),
                      ...item,
                      dsns: item.metadata
                        ? Object.keys(item.metadata.OneCampus)[0]
                        : 'none',
                    })),
                  ]);
                }

                if (tokenInfo.code) {
                  setSteps(Route.ClassroomCode);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  return;
                }

                setSteps(Route.Onecampus);
              });
            }
          });
      });
    // eslint-disable-next-line
  }, []);

  const getSchool = (token) => {
    setClassesLoading(true);

    fetch(
      SERVICE_CC_ITEMS.replace(/_DSNS_/gi, token.dsns).replace(
        /_ACCESS_TOKEN_/gi,
        token.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        let items = []
          .concat(((response.__Body || response).Items || {}).Item || [])
          .map((item) => ({
            ...item,
            key: `${token.dsns}.${item.Type.toLowerCase()}.${item.UID}`,
            avatar: `${item.Name}`.substr(0, 1),
          }));

        const docs = items.map((item) => item.key);

        if (docs.length > 0) {
          const chunk = (arr, size) =>
            Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
              arr.slice(i * size, i * size + size),
            );

          const chunks = chunk(docs, 10);
          const promises = chunks.map((item) => {
            return new Promise((rs) => {
              firebase
                .firestore()
                .collection('classrooms')
                .where(firebase.firestore.FieldPath.documentId(), 'in', item)
                .get()
                .then((querySnapshot) => {
                  const docs = [];
                  querySnapshot.forEach((doc) => {
                    const { status, timestamp } = doc.data();
                    docs.push({
                      id: doc.id,
                      status:
                        timestamp.seconds >
                        Math.ceil(Date.now() / 1000) - 60 * 50
                          ? status
                          : 'close',
                      timestamp,
                    });
                  });

                  rs(docs);
                });
            });
          });

          Promise.all(promises).then((results) => {
            results.forEach((docs) => {
              docs.forEach((doc) => {
                items
                  .filter((item) => item.key === doc.id)
                  .forEach((item) => (item.status = doc.status));
              });
            });

            setClasses(items);
            setClassesLoading(false);
          });
        } else {
          setClasses(items);
          setClassesLoading(false);
        }
      });
  };

  const get1Know = (org) => {
    if (!org) return;

    setGroupsLoading(true);

    fetch(
      SERVICE_GROUPS.replace(
        /_ACCESS_TOKEN_/gi,
        org.dsns
          ? `${tokenInfo.accessToken}&dsns=${org.dsns}`
          : tokenInfo.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          let items = response
            .filter((item) => item.role === 'member')
            .sort((x, y) => (x.name > y.name ? 1 : -1))
            .map((item) => ({ ...item, key: `1know.group.${item.uqid}` }));

          const docIds = items.map((item) => item.key);

          if (docIds.length > 0) {
            firebase
              .firestore()
              .collection('classrooms')
              .where(firebase.firestore.FieldPath.documentId(), 'in', docIds)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const { status, timestamp } = doc.data();
                  items
                    .filter((item) => item.key === doc.id)
                    .forEach(
                      (item) =>
                        (item.status =
                          timestamp.seconds >
                          Math.ceil(Date.now() / 1000) - 60 * 50
                            ? status
                            : 'close'),
                    );
                });

                setGroups(items);
                setGroupsLoading(false);
              });
          } else {
            setGroups(items);
            setGroupsLoading(false);
          }

          return;
        }

        setGroups([]);
        setGroupsLoading(false);
      })
      .catch((error) => {
        setGroups([]);
        setGroupsLoading(false);
      });
  };

  const getStep = (step) => {
    switch (step) {
      case 'School': {
        return (
          <React.Fragment>
            <List>
              {schools.map((item, index) => (
                <React.Fragment key={item.key}>
                  <ListItem
                    button
                    onClick={() => {
                      getSchool(item.token);
                      setSteps(Route.OnecampusClass);
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.primary.main,
                          ),
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {item.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {schools.length > 1 && index < schools.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
              {schools.length > 0 && <Divider variant="inset" />}
              <ListItem
                key="1know"
                button
                onClick={() => {
                  if (orgs.length > 1) {
                    setSteps(Route.OneknowOrgs);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  } else {
                    get1Know(orgs[0]);
                    setSteps(Route.OneknowSelfGroup);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    1K
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="1Know" />
              </ListItem>
              <Divider variant="inset" />
              <ListItem
                key="classroomcode"
                button
                onClick={() => {
                  setSteps(Route.ClassroomCode);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    教
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="教室代碼" />
              </ListItem>
            </List>
          </React.Fragment>
        );
      }
      case 'Class': {
        return (
          <React.Fragment>
            {classesLoading ? (
              <CircularProgress
                color="primary"
                style={{ margin: theme.spacing(2) }}
              />
            ) : classes.length === 0 ? (
              <Typography variant="h6" color="secondary">
                尚無可以上課的班級
              </Typography>
            ) : (
              <List>
                {classes.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      // disabled={item.status !== 'open'}
                      onClick={() =>
                        callback({
                          action: 'SIGN_IN',
                          payload: {
                            roomId: item.key,
                            accessToken: tokenInfo.accessToken,
                          },
                        })
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main,
                            ),
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ secondary: styles.secondaryText }}
                        primary={item.Name}
                        secondary={item.status === 'open' ? '上課中' : ''}
                      />
                    </ListItem>
                    {classes.length > 1 && index < classes.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            <div className={styles.buttonbar}>
              {tokenInfo.dsns === '1campus' && (
                <Button
                  color="primary"
                  onClick={() => {
                    setSteps(Route.Onecampus);
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }}
                >
                  上一步
                </Button>
              )}
            </div>
          </React.Fragment>
        );
      }
      case 'Org': {
        return (
          <React.Fragment>
            <List>
              {orgs.map((item, index) => (
                <React.Fragment key={item.key}>
                  <ListItem
                    button
                    onClick={() => {
                      get1Know(item);
                      setSteps(Route.OneknowOrgGroup);
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.primary.main,
                          ),
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {item.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {orgs.length > 1 && index < orgs.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
            </List>
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.Onecampus);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Group': {
        return (
          <React.Fragment>
            {groups.length === 0 ? (
              groupsLoading ? (
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2) }}
                />
              ) : (
                <React.Fragment>
                  <Typography variant="h6" color="secondary">
                    尚無可以上課的群組
                  </Typography>
                </React.Fragment>
              )
            ) : (
              <List>
                {groups.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      // disabled={item.status !== 'open'}
                      onClick={() =>
                        callback({
                          action: 'SIGN_IN',
                          payload: {
                            roomId: item.key,
                            accessToken: tokenInfo.accessToken,
                          },
                        })
                      }
                    >
                      <ListItemAvatar>
                        <Avatar src={item.logo}></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        classes={{ secondary: styles.secondaryText }}
                        primary={item.name}
                        secondary={item.status === 'open' ? '上課中' : ''}
                      />
                    </ListItem>
                    {groups.length > 1 && index < groups.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  if (orgs.length > 1) {
                    setSteps(Route.OneknowOrgs);
                  } else {
                    setSteps(Route.Onecampus);
                  }

                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Code': {
        return (
          <React.Fragment>
            <TextField
              autoFocus
              fullWidth
              placeholder="教室代碼"
              value={roomCode}
              variant="outlined"
              onChange={(e) => setRoomCode(e.target.value)}
            />
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.Onecampus);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  firebase
                    .firestore()
                    .collection('classrooms')
                    .where('code', '==', roomCode.toUpperCase())
                    .get()
                    .then((querySnapshot) => {
                      if (querySnapshot.size === 1) {
                        querySnapshot.forEach((doc) => {
                          callback({
                            action: 'SIGN_IN',
                            payload: {
                              roomId: doc.id,
                              accessToken: tokenInfo.accessToken,
                            },
                          });
                        });
                      }
                    });
                }}
              >
                加入教室
              </Button>
            </div>
          </React.Fragment>
        );
      }
      default: {
        /** empty */
      }
    }
  };

  return loading ? (
    <Backdrop open={true}>
      <div className={styles.loading}>
        <CircularProgress
          color="primary"
          style={{ margin: theme.spacing(2), color: '#f1f1f1' }}
        />
        <Typography variant="h6" style={{ color: '#f1f1f1' }}>
          資料讀取中...
        </Typography>
      </div>
    </Backdrop>
  ) : (
    <Dialog open={true}>
      <DialogActions>
        <Typography
          variant="body2"
          color="primary"
          style={{ marginLeft: theme.spacing(1) }}
        >
          ISCHOOL INC. {state.version}
        </Typography>
        <div style={{ flex: 1 }} />
        {!window.opener && (
          <IconButton
            size="small"
            onClick={() =>
              callback({
                action: 'EXIT_CHOOSER',
              })
            }
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogActions>
      <DialogContent
        style={{ padding: theme.spacing(1), width: theme.spacing(34) }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ padding: theme.spacing(1) }}
        >
          {steps.map((step) => (
            <Step key={step.key}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent style={{ padding: 0 }}>
                {getStep(step.key)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default Component;
