import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../../App';

import Main from './main';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
}));

const SERVICE_USER_TOKEN =
  'https://us-central1-classroom-1campus.cloudfunctions.net/getUserToken?accessToken=_ACCESS_TOKEN_';
const SERVICE_AUTH =
  'https://us-central1-classroom-1campus.cloudfunctions.net/auth?accessToken=_ACCESS_TOKEN_&state=users:_UUID_';
const SERVICE_USER_INFO =
  'https://m.1know.net/services/account/user?accessToken=_ACCESS_TOKEN_';
const SERVICE_MY_INFO =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.student/classroom.GetMyInfo?stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter&content=RequestSessionID&rsptype=json';
const SERVICE_SESSION_ID =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.student/DS.Base.Connect?stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter&content=RequestSessionID&rsptype=json';

const Component = () => {
  const classes = useStyles();

  const { state, dispatch } = useContext(AppContext);

  const [target, setTarget] = useState(null);
  const [config, setConfig] = useState(null);
  const [behavior, setBehavior] = useState(null);

  useEffect(() => {
    let authInterval;
    let unsubscribe1 = () => {};
    let unsubscribe2 = () => {};

    fetch(SERVICE_USER_TOKEN.replace(/_ACCESS_TOKEN_/gi, state.accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (!response.token) {
          window.localStorage.clear();
          window.location.reload();
          return;
        }

        firebase
          .auth()
          .signInWithCustomToken(response.token)
          .then(() => {
            const url = SERVICE_AUTH.replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ).replace(/_UUID_/gi, response.uuid);

            fetch(url);
            authInterval = setInterval(() => fetch(url), 3000000);

            unsubscribe1 = firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}`)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  const { status } = doc.data();

                  if (status === 'close') {
                    window.localStorage.clear();
                    window.location.reload();
                  }
                }
              });

            unsubscribe2 = firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/events/behavior`)
              .onSnapshot((doc) => {
                if (doc.exists) {
                  setBehavior(doc.data());
                  setTimeout(() => setBehavior(null), 3000);
                }
              });

            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const target = doc.data();
                  const { status, dsns } = target;

                  if (status === 'close') {
                    window.localStorage.clear();
                    window.location.reload();
                    return;
                  }

                  let domain = '';
                  const match = dsns.match(
                    /([a-z0-9.]+)\.([a-z0-9]+)\.edu\.tw/,
                  );

                  if (match) {
                    domain = `${match[2]}.edu.tw`;
                  }

                  setTarget({ ...target, domain, myInfo: response });
                } else {
                  window.localStorage.clear();
                  window.location.reload();
                }
              })
              .catch(() => {
                window.localStorage.clear();
                window.location.reload();
              });
          });
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
      window.clearInterval(authInterval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!target) return;

    Promise.all([
      new Promise((rs) => {
        fetch(
          SERVICE_USER_INFO.replace(/_ACCESS_TOKEN_/gi, state.accessToken),
          {
            method: 'POST',
          },
        )
          .then((response) => response.json())
          .then((response) => {
            if (!response.error) {
              return rs({
                ...response,
                ID: response.uqid,
                Name: `${response.last_name}${response.first_name}` || '訪客',
              });
            }

            rs(null);
          })
          .catch(() => rs(null));
      }),
      new Promise((rs) => {
        if (!['1campus', '1know'].includes(target.dsns)) {
          fetch(
            SERVICE_MY_INFO.replace(/_DSNS_/gi, target.dsns).replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ),
          )
            .then((response) => response.json())
            .then((response) => {
              rs((response.__Body || response).Student);
            })
            .catch(() => rs(null));
        } else {
          rs(null);
        }
      }),
    ]).then((results) => {
      dispatch({
        type: 'SET_MY_INFO',
        payload: {
          ...target.myInfo,
          ...(results[0] || {}),
          ...(results[1] || {}),
        },
      });
    });

    Promise.all([
      new Promise((rs) => {
        firebase
          .firestore()
          .doc('/configs/student.1campus')
          .get()
          .then((doc) => rs(doc.exists ? doc.data() : null));
      }),
      new Promise((rs) => {
        firebase
          .firestore()
          .doc(`/configs/student.${target.domain || 'NONE_DOMAIN'}`)
          .get()
          .then((doc) => rs(doc.exists ? doc.data() : null));
      }),
      new Promise((rs) => {
        firebase
          .firestore()
          .doc(`/configs/student.${target.dsns || 'NONE_DSNS'}`)
          .get()
          .then((doc) => rs(doc.exists ? doc.data() : null));
      }),
      new Promise((rs) => {
        if (!['1campus', '1know'].includes(target.dsns)) {
          fetch(
            SERVICE_SESSION_ID.replace(/_DSNS_/gi, target.dsns).replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ),
          )
            .then((response) => response.json())
            .then((response) => {
              rs(response.SessionID);
            });
        } else {
          rs(null);
        }
      }),
    ]).then(([defaultConfig, domainConfig, dsnsConfig, sessionid]) => {
      setConfig(
        JSON.parse((dsnsConfig || domainConfig || defaultConfig || {}).content),
      );

      if (sessionid) {
        return dispatch({
          type: 'SET_TARGET',
          payload: { ...target, sessionid },
        });
      }

      dispatch({ type: 'SET_TARGET', payload: target });
    });
    // eslint-disable-next-line
  }, [target]);

  useEffect(() => {
    if (!config) return;
    dispatch({ type: 'SET_CONFIG', payload: config });
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    if (!state.chooseAppsItem) return;

    const data = { ...state.chooseAppsItem };

    if (data.url) {
      openWeb(data);
    } else {
      (config || []).forEach((category) => {
        if (category.type === 'clouds') {
          const [cloud] = category.items.filter(
            (item) => item.key === data.key,
          );

          if (cloud) {
            openWeb({ ...data, url: cloud.url });
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [state.chooseAppsItem]);

  const openWeb = (item) => {
    if (!item.url) return;

    window.open(item.url.replace(/_ACCESSTOKEN_/, state.accessToken), '_blank');
  };

  return (
    <div className={classes.root}>
      {behavior && (
        <Dialog open={true}>
          <DialogTitle>{behavior.student.name}</DialogTitle>
          <DialogContent>
            <Typography
              variant="h1"
              color={behavior.points > 0 ? 'primary' : 'secondary'}
            >
              {behavior.name}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
      <Main />
    </div>
  );
};

export default Component;
