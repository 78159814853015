import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fade,
  Fab,
  Paper,
  Slider,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(288px, 1fr))',
    gridGap: theme.spacing(2),
  },
  send: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  option: {
    padding: theme.spacing(3),
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    background: 'rgba(0,0,0,0.67)',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [docId, setDocId] = useState(0);
  const [content, setContent] = useState([]);
  const [options, setOptions] = useState([]);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('close');

  useEffect(() => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { data, timestamp } = doc.data();
          setOptions(data);
          setContent(data.map((i) => 1));
          setDocId(timestamp);
        }
      });

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { status } = doc.data();
          setStatus(status);
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  if (options.length > 0) {
    return (
      <Fade in timeout={1000}>
        <div className={classes.main}>
          {status === 'close' && <div className={classes.overlay} />}
          <Fab
            className={classes.send}
            color="secondary"
            onClick={() => {
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/assess/${docId}`)
                .update({
                  [`student_${state.myInfo.ID}`]: {
                    name: state.myInfo.Name,
                    content,
                  },
                });
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
                .update({
                  [`student_${state.myInfo.ID}`]: {
                    name: state.myInfo.Name,
                    content,
                  },
                })
                .then(() => {
                  setMessage(`評分已送出`);
                  setOpen(true);
                });
            }}
          >
            <SendIcon />
          </Fab>
          <div className={classes.content}>
            {options.map((option, index) => (
              <Paper className={classes.option} key={`option-${index + 1}`}>
                <Typography variant="h5" gutterBottom>
                  {`${option} ${content[index]} 分`}
                </Typography>
                <Slider
                  defaultValue={1}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  min={1}
                  max={10}
                  onChange={(e, v) => {
                    content[index] = v;
                    setContent([...content]);
                  }}
                />
              </Paper>
            ))}

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              message={message}
              open={open}
              onClose={() => setOpen(false)}
              autoHideDuration={3000}
            />
          </div>
        </div>
      </Fade>
    );
  }

  return <React.Fragment />;
};

export default Component;
