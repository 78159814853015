import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Fade, Snackbar, Typography } from '@material-ui/core';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(288px, 1fr))',
    gridGap: theme.spacing(2),
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    background: 'rgba(0,0,0,0.67)',
  },
}));

const colors = [
  '#6388b4',
  '#ffae34',
  '#ef6f6a',
  '#8cc2ca',
  '#55ad89',
  '#c3bc3f',
  '#bb7693',
  '#baa094',
  '#a9b5ae',
  '#767676',
];

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [color] = useState(colors[Math.floor(Math.random() * 10)]);
  const [docId, setDocId] = useState(0);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [myData, setMyData] = useState(null);
  const [status, setStatus] = useState('close');

  useEffect(() => {
    if (!state.myInfo) return;

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setMyData(data[`student_${state.myInfo.ID}`]);
          setDocId(data.timestamp);
        }
      });

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { status } = doc.data();
          setStatus(status);
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.myInfo]);

  return (
    <Fade in timeout={1000}>
      <div className={classes.main}>
        {status === 'close' && <div className={classes.overlay} />}
        <div className={classes.content}>
          <Button
            style={{ backgroundColor: color }}
            variant="contained"
            color="primary"
            onClick={() => {
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/attendances/${docId}`)
                .update({
                  [`student_${state.myInfo.ID}`]: {
                    ...myData,
                    status: 'V',
                    timestamp: Date.now(),
                  },
                });
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/attendances/0000000000000`)
                .update({
                  [`student_${state.myInfo.ID}`]: {
                    ...myData,
                    status: 'V',
                    timestamp: Date.now(),
                  },
                })
                .then(() => {
                  setMessage(`已回報!`);
                  setOpen(true);
                });
            }}
          >
            <Typography variant="h3" color="inherit">
              我有到哦!
            </Typography>
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            message={message}
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={3000}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Component;
