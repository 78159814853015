import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Popover,
  TextField,
} from '@material-ui/core';
import {
  Send as SendIcon,
  ThumbUp as PlusIcon,
  ThumbDown as NegIcon,
  ThumbsUpDown as NormalIcon,
} from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  list: {
    flex: 1,
    overflow: 'auto',
  },
  listItemTextPrimary: {
    wordBreak: 'break-all',
    marginRight: theme.spacing(1),
  },
  selfListItemTextSecondary: {
    color: theme.palette.secondary.main,
  },
  inputBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 2),
    backgroundColor: '#f1f1f1',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [chats, setChats] = useState([]);
  const [chatText, setChatText] = useState('');
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!state.roomId || !state.students) return;

    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/chats`)
      .onSnapshot((snapshot) => {
        const chats = [];
        snapshot.forEach((doc) => {
          const item = doc.data();

          chats.push({
            id: doc.id,
            ...item,
            self: item.target === `student_${state.myInfo.ID}`,
          });
        });
        chats.sort((x, y) => (x.time > y.time ? 1 : -1));

        setChats(chats);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.roomId, state.students]);

  const plus = () => {
    if (!selected) return;

    const key = `student_${state.myInfo.ID}`;
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/chats/${selected.id}`)
      .update({ [key]: selected[key] === 1 ? 0 : 1 });

    setSelected(null);
    setAnchorEl(null);
  };

  const neg = () => {
    if (!selected) return;

    const key = `student_${state.myInfo.ID}`;
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/chats/${selected.id}`)
      .update({ [key]: selected[key] === -1 ? 0 : -1 });

    setSelected(null);
    setAnchorEl(null);
  };

  const send = () => {
    firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/chats`)
      .add({
        author: state.myInfo.Name,
        content: chatText,
        time: Date.now(),
        target: `student_${state.myInfo.ID}`,
      })
      .then(() => setChatText(''));
  };

  return (
    <div className={classes.content}>
      {selected && (
        <Popover
          open={Boolean(selected)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => {
            setSelected(null);
            setAnchorEl(null);
          }}
          disableRestoreFocus
        >
          <List>
            <ListItem>
              <IconButton color="primary" onClick={plus}>
                <PlusIcon />
              </IconButton>
              <div style={{ width: theme.spacing(1) }} />
              <IconButton color="secondary" onClick={neg}>
                <NegIcon />
              </IconButton>
            </ListItem>
          </List>
        </Popover>
      )}
      <List className={classes.list}>
        {chats.map((item, cdx) => (
          <React.Fragment key={`chat-${item.id}`}>
            <ListItem>
              <ListItemText
                classes={
                  item.self
                    ? {
                        primary: classes.listItemTextPrimary,
                        secondary: classes.selfListItemTextSecondary,
                      }
                    : { primary: classes.listItemTextPrimary }
                }
                primary={item.content}
                secondary={item.author}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  color={
                    item[`student_${state.myInfo.ID}`] === 1
                      ? 'primary'
                      : item[`student_${state.myInfo.ID}`] === -1
                      ? 'secondary'
                      : 'default'
                  }
                  onClick={(e) => {
                    setSelected(item);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  {item[`student_${state.myInfo.ID}`] === 1 ? (
                    <PlusIcon />
                  ) : item[`student_${state.myInfo.ID}`] === -1 ? (
                    <NegIcon />
                  ) : (
                    <NormalIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <div
        className={classes.inputBox}
        style={state.darkMode ? { backgroundColor: 'transparent' } : {}}
      >
        <TextField
          placeholder="可以隨時提出您的問題..."
          fullWidth
          value={chatText}
          onChange={(e) => setChatText(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode !== 13 || !chatText) return;
            send();
          }}
        />
        <div style={{ width: theme.spacing(1) }} />
        <IconButton
          color="primary"
          onClick={() => {
            if (!chatText) return;
            send();
          }}
        >
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Component;
