import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import { useMediaQuery } from '@material-ui/core';
import { Route, Router, Switch } from 'react-router-dom';
import { createMemoryHistory } from 'history';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

import * as Assets from './assets';
import { Chooser, Learn, Main } from './components';

const firebaseConfig = {
  apiKey: 'AIzaSyBTAR8KFcKwzx-JsdqWlTU7UmXG4NaThGA',
  authDomain: 'classroom-1campus.firebaseapp.com',
  databaseURL: 'https://classroom-1campus.firebaseio.com',
  projectId: 'classroom-1campus',
  storageBucket: 'classroom-1campus.appspot.com',
  messagingSenderId: '973016711227',
  appId: '1:973016711227:web:f616b25243cd6b92c21c1e',
  measurementId: 'G-8H3JZJ3YHW',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

const AppContext = createContext({});
const history = createMemoryHistory();

const initialState = {
  version: '21J23W23',
  darkMode: false,
};

const stateReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ACCESS_TOKEN': {
      return { ...state, accessToken: payload };
    }
    case 'SET_MY_INFO': {
      return { ...state, myInfo: payload };
    }
    case 'SET_ROOM_ID': {
      return { ...state, roomId: payload };
    }
    case 'SET_TARGET': {
      return { ...state, target: payload };
    }
    case 'SET_CONFIG': {
      return { ...state, config: payload };
    }
    case 'SET_STUDENTS': {
      return { ...state, students: payload };
    }
    case 'CHOOSE_APPS_ITEM': {
      return { ...state, chooseAppsItem: payload };
    }
    case 'TOGGLE_DARK_MODE': {
      return { ...state, darkMode: payload };
    }
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
  },
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
}));

const Component = () => {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [state, dispatch] = useReducer(stateReducer, initialState);

  useMemo(
    () =>
      dispatch({
        type: 'TOGGLE_DARK_MODE',
        payload: prefersDarkMode,
      }),
    [prefersDarkMode],
  );

  let theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: { main: state.darkMode ? teal[400] : teal[700] },
        type: state.darkMode ? 'dark' : 'light',
      },
    });
    // eslint-disable-next-line
  }, [state.darkMode]);

  useEffect(() => {
    try {
      const tokenInfo = JSON.parse(window.localStorage.getItem('tokenInfo'));
      if (tokenInfo) {
        return history.replace('/chooser');
      }

      const roomId = window.localStorage.getItem('roomId');
      const accessToken = window.localStorage.getItem('accessToken');
      if (roomId && accessToken) {
        dispatch({ type: 'SET_ROOM_ID', payload: roomId });
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
        return history.replace('/learn');
      }
    } catch {
      // empty
    }

    history.replace('/main');
    // eslint-disable-next-line
  }, []);

  const chooserCallback = (data) => {
    window.localStorage.removeItem('tokenInfo');

    const { action, payload } = data;

    switch (action) {
      case 'SIGN_IN': {
        window.localStorage.setItem('roomId', payload.roomId);
        dispatch({ type: 'SET_ROOM_ID', payload: payload.roomId });

        window.localStorage.setItem('accessToken', payload.accessToken);
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: payload.accessToken });

        history.replace('/learn');
        break;
      }
      case 'EXIT_CHOOSER': {
        history.replace('/main');
        break;
      }
      default: {
        /** empty */
      }
    }
  };

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.banner}></div>
        <div className={classes.root}>
          <Router history={history}>
            <Switch>
              <Route
                path="/chooser"
                children={<Chooser callback={chooserCallback} />}
              />
              <Route path="/main" children={<Main />} />
              <Route path="/learn" children={<Learn />} />
            </Switch>
          </Router>
        </div>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
};

export default Component;
export { AppContext };
