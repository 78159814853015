import React, { useContext, useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../../App';

import Apps from './apps';
import Interactive from './interactive';
import OneKnow from './oneknow';
import Assess from './assess';
import Vote from './vote';
import Attendance from './attendance';

const Component = () => {
  const { state } = useContext(AppContext);
  const [target, setTarget] = useState('apps');

  useEffect(() => {
    if (!state.roomId) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/main`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { key } = doc.data();
          setTimeout(() => {
            switch (key) {
              case 'classroom.interactive.open':
                setTarget('interactive');
                break;
              case 'classroom.1know.open':
                setTarget('oneknow');
                break;
              case 'classroom.assess.open':
                setTarget('assess');
                break;
              case 'classroom.vote.open':
                setTarget('vote');
                break;
              case 'classroom.attendance.open':
                setTarget('attendance');
                break;
              default:
                setTarget('apps');
            }
          }, 300);
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.roomId]);

  switch (target) {
    case 'apps':
      return <Apps />;
    case 'interactive':
      return <Interactive />;
    case 'oneknow':
      return <OneKnow />;
    case 'assess':
      return <Assess />;
    case 'vote':
      return <Vote />;
    case 'attendance':
      return <Attendance />;
    default:
      return <Apps />;
  }
};

export default Component;
