import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Fab, Fade, IconButton, Tooltip, Typography } from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  fab,
  faAppStoreIos,
  faFacebook,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';

import * as Assets from '../assets';
import { AppContext } from '../App';

library.add(fab, fas, faAppStoreIos, faEnvelope, faFacebook, faGooglePlay);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(25),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  connectbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1),
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);

  return (
    <Fade in timeout={1000}>
      <div className={classes.root}>
        <div className={classes.main}>
          <img
            src={Assets.LightLogo}
            style={{ width: theme.spacing(30), marginBottom: theme.spacing(5) }}
            alt="Logo"
          />
          <div className={classes.content}>
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              onClick={(event) => {
                let params = {};
                try {
                  params = JSON.parse(
                    `{"${decodeURIComponent(
                      window.location.search
                        .substr(1)
                        .replace(/=/g, '":"')
                        .replace(/&/g, '","'),
                    )}"}`,
                  );
                } catch {}

                const clientId = '140632d783e5fcc4810c0b08d103ec9b';
                const scope = [
                  'User.Mail',
                  'User.BasicInfo',
                  'User.Application',
                  '*:1campus.mobile.v2.student',
                  'http://1know.net/personal',
                ].join();
                const state = params.code
                  ? `1campus:${params.code}`
                  : '1campus';
                const url = `https://auth.ischool.com.tw/oauth/authorize.php?client_id=${clientId}&response_type=token&redirect_uri=${window.location.origin}&scope=${scope}&state=${state}`;

                window.location.replace(url);
              }}
            >
              <AccountIcon style={{ marginRight: theme.spacing(1) }} />
              1Campus 帳號登入
            </Fab>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.connectbar}>
            <Tooltip title="客服信箱">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  window.open('mailto:support@ischool.com.tw', '_blank')
                }
              >
                <FontAwesomeIcon icon={['fas', 'envelope']} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Facebook 粉絲團">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/Classroom-104202620941684',
                    '_blank',
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </IconButton>
            </Tooltip>
            <Tooltip title="1Campus App Android">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=tw.com.ischool.onecampusmobile',
                    '_blank',
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'google-play']} />
              </IconButton>
            </Tooltip>
            <Tooltip title="1Campus App iOS">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  window.open(
                    'https://apps.apple.com/app/1campus/id1273657906',
                    '_blank',
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'app-store-ios']} />
              </IconButton>
            </Tooltip>
          </div>
          <Typography variant="caption" style={{ color: 'rgba(0,0,0,.54)' }}>
            {`POWERED BY ISCHOOL INC. ${state.version}`}
          </Typography>
        </div>
      </div>
    </Fade>
  );
};

export default Component;
