import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  Chat as ChatIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  ColorLens as ThemeIcon,
  ExitToApp as ExitIcon,
  Menu as MenuIcon,
  MoreVert as MoreIcon,
  VoiceChat as VoiceChatIcon,
} from '@material-ui/icons';
import QRCode from 'qrcode.react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

import Content from './content';
import Students from './students';
import Chat from './chat';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  logoHeader: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xsMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const { state, dispatch } = useContext(AppContext);
  const [studentOpen, setStudentOpen] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [meet, setMeet] = useState(false);
  const [meetUrl, setMeetUrl] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [exitAnchorEl, setExitAnchorEl] = useState(null);

  useEffect(() => {
    if (!state.roomId) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/meet`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { url } = doc.data();
          if (url && url !== meetUrl) {
            setMeet(true);
            setMeetUrl(url);
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.roomId]);

  useEffect(() => {
    if (!state.myInfo) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/users/${state.myInfo.uuid}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const token = doc.data();
          window.localStorage.setItem('accessToken', token.accessToken);
          dispatch({ type: 'SET_ACCESS_TOKEN', payload: token.accessToken });
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.myInfo]);

  return (
    <div className={classes.root}>
      {meet && (
        <Dialog open={true} onClose={() => setMeet(false)}>
          {!meetUrl && (
            <DialogContent>
              <DialogContentText>
                教師尚未開啟 Google Meet 視訊會議
              </DialogContentText>
            </DialogContent>
          )}
          {meetUrl && (
            <React.Fragment>
              <DialogTitle>Google Meet 視訊會議</DialogTitle>
              <DialogContent>
                <QRCode
                  value={meetUrl}
                  size={240}
                  fgColor={state.darkMode ? '#f1f1f1' : '#454545'}
                  bgColor="transparent"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(meetUrl, '_blank');
                  }}
                />
                <DialogContentText style={{ marginTop: theme.spacing(2) }}>
                  掃描或點擊 QRCode 加入
                </DialogContentText>
              </DialogContent>
            </React.Fragment>
          )}
        </Dialog>
      )}
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setMenuAnchorEl(null)}
        disableRestoreFocus
      >
        <List>
          <ListItem
            button
            onClick={() => {
              setChatOpen(true);
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText>互動問答</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              dispatch({
                type: 'TOGGLE_DARK_MODE',
                payload: !state.darkMode,
              });
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ThemeIcon />
            </ListItemIcon>
            <ListItemText>切換佈景</ListItemText>
          </ListItem>
          <Divider variant="fullWidth" />
          <ListItem
            button
            onClick={() => {
              setMeet(true);
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <VoiceChatIcon />
            </ListItemIcon>
            <ListItemText>Google Meet</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setExitAnchorEl(menuAnchorEl);
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText>離開教室</ListItemText>
          </ListItem>
        </List>
      </Popover>
      <Popover
        open={Boolean(exitAnchorEl)}
        anchorEl={exitAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setExitAnchorEl(null)}
        disableRestoreFocus
      >
        <List subheader={<ListSubheader>確定離開教室？</ListSubheader>}>
          <ListItem>
            <Button color="secondary" onClick={() => setExitAnchorEl(null)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() => {
                window.localStorage.clear();
                window.location.reload();
              }}
            >
              確定
            </Button>
          </ListItem>
        </List>
      </Popover>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: studentOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setStudentOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, studentOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {state.myInfo && state.target && (
            <Typography variant="h6" noWrap>
              {`${state.myInfo.Name} 您好，這堂課是 ${state.target.classroom.name}`}
            </Typography>
          )}
          <div style={{ flex: 1 }}></div>
          {xsMediaQuery ? (
            <IconButton
              color="inherit"
              onClick={(event) => setMenuAnchorEl(event.currentTarget)}
            >
              <MoreIcon />
            </IconButton>
          ) : (
            <React.Fragment>
              <Tooltip title="互動問答">
                <IconButton color="inherit" onClick={() => setChatOpen(true)}>
                  <ChatIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="切換佈景">
                <IconButton
                  color="inherit"
                  onClick={() =>
                    dispatch({
                      type: 'TOGGLE_DARK_MODE',
                      payload: !state.darkMode,
                    })
                  }
                >
                  <ThemeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Google Meet">
                <IconButton color="inherit" onClick={() => setMeet(true)}>
                  <VoiceChatIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="離開教室">
                <IconButton
                  color="inherit"
                  onClick={(event) => setExitAnchorEl(event.currentTarget)}
                >
                  <ExitIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="left"
        open={studentOpen}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.logoHeader}>
            <img
              src={state.darkMode ? Assets.DarkLogo : Assets.LightLogo}
              alt="Classroom"
              style={{ height: 40 }}
            />
            <Typography
              variant="caption"
              color="primary"
              style={{ margin: theme.spacing(0, 1) }}
            >
              {state.version}
            </Typography>
          </div>
          <IconButton onClick={() => setStudentOpen(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Students />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: studentOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Content />
      </main>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={chatOpen}
        onClose={() => setChatOpen(false)}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h5" color="primary">
            互動問答
          </Typography>
          <div style={{ flex: 1 }}></div>
          <IconButton onClick={() => setChatOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Chat />
      </Drawer>
    </div>
  );
};

export default Component;
